@tailwind base;
@tailwind components;
@tailwind utilities;

.dark-ov {
  background-color: rgba(0, 0, 0, 0.3);
  @apply w-full h-full top-0 left-0 absolute z-10;
}

.text-box {
  @apply absolute z-20 text-white left-10 top-96 w-1/2;
}

body {
  background-color: #252525;
}

.play-ic {
  @apply text-red-500 w-20 h-20 z-20 absolute;
  bottom: 30%;
  left: 50%;
  transform: translateX(-50%);
}

.active-text {
  @apply text-2xl font-semibold text-red-500;
}

.non-active-text {
  @apply text-2xl font-semibold text-white;
}

.detail-container {
  @apply w-1/2 mx-auto p-4 flex items-start justify-center absolute z-40;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.09);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.6px);
  -webkit-backdrop-filter: blur(7.6px);
}
