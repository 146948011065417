.auth-form-container,
.login-form,
.register-form {
  display: flex;
  flex-direction: column;
}

.LoginPage,
.RegisterPage {
  text-align: center;
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  color: white;
  background: url(https://assets.nflxext.com/ffe/siteui/vlv3/efb4855d-e702-43e5-9997-bba0154152e0/f4c2880f-1f84-4b99-8a58-1854ab045684/ID-en-20230417-popsignuptwoweeks-perspective_alpha_website_large.jpg);
}

.main {
  background: rgb(10, 6, 6);
  opacity: 0.8;
}

@media screen and (min-width: 600px) {
  .auth-form-container {
    padding: 5rem;
    border: 1px solid white;
    border-radius: 10px;
    margin: 0.5rem;
  }
}

label {
  text-align: left;
  padding: 0.25rem 0;
}

input {
  margin: 0.5rem 0;
  padding: 1rem;
  border: none;
  border-radius: 10px;
  color: black;
}

button {
  border: none;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  color: #7439db;
}

.link-btn {
  background: none;
  color: white;
  text-decoration: underline;
}
